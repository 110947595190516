import * as React from "react"
import { SpHeader } from 'components/organisms/header'
import { InquiryButtonMedium } from "components/atoms/inquiry-button-medium"
import { PhoneAnchor } from 'components/atoms/phone-anchor'
import TopImg1 from 'images/image--top1.webp'
import TopImg2 from 'images/image--top2.webp'
import TopImg3 from 'images/image--top3.webp'
import TopImg4 from 'images/image--top4.webp'

const Top = () => {

  const Slider = () => {
    return(
      <div className="top__slider">
        <img
          src={`.${ TopImg1 }`}
          alt="水産業1"
          className="top__image top__image--1"
        />
        <img
          src={`.${ TopImg2 }`}
          alt="水産業2"
          className="top__image top__image--2"
        />
        <img
          src={`.${ TopImg3 }`}
          alt="水産業3"
          className="top__image top__image--3"
        />
        <img
          src={`.${ TopImg4 }`}
          alt="水産業4"
          className="top__image top__image--4"
        />
      </div>
    )
  }

  return (
    <section className="top">
      <div className="top__items-box--first">
        <h2 className="top__message">
          「人材が足りない」<br />「採用活動に回す時間がない」<br />そんな企業様をサポートします！
        </h2>
        <div className="top__button-box">
          <InquiryButtonMedium />
        </div>
        <div className="top__phone-number">
          <span className="text--small">TEL</span><PhoneAnchor />
        </div>
      </div>
      <div className="top__items-box--second">
        <SpHeader />

        <Slider />

      </div>
    </section>
  )
}
export default Top