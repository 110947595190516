import * as React from "react"
import gatsbyConfig from "../../../gatsby-config"
import InquiryImg from 'images/icon--inquiry.webp'
import { SP_INQUIRY_BUTTON_TEXT } from 'settings'

/**
 * SPボタン
 * 使用箇所：固定フッター（SP）
 **/ 
export const InquiryButtonSp = () => {
  return (
    <a href={`${ gatsbyConfig.siteMetadata.siteUrl }/inquiry`}>
      <img src={ InquiryImg } alt="envelope icon" className="sp-contact-buttons__icon" />
      <span className="sp-contact-buttons__text">{ SP_INQUIRY_BUTTON_TEXT }</span>
    </a>
  )
}