import * as React from "react"

import { InquiryButtonSp } from 'components/atoms/inquiry-button-sp'
import { CallButtonSp } from 'components/atoms/call-button-sp'

const SpContactButtons = () => {
  return (
    <ul className="sp-contact-buttons">
      <li className="sp-contact-buttons__item-box">
        <InquiryButtonSp />
      </li>
      <li className="sp-contact-buttons__item-box">
        <CallButtonSp />
      </li>
    </ul>
  )
}
export default SpContactButtons