// common
import * as React from "react"

const Overview = () => {
  return (
    <section className="overview">
      <ul>
        <li className="overview__text-box">
          水産業界の皆さま、<br className="pc" />
          人材採用でお困りではありませんか？<br className="pc" />
          「WaaF」は漁師や水産加工業の人手不足を解消する<br className="pc" />
          ために生まれた採用支援サービスです。
        </li>
      </ul>
    </section>
  )
}
export default Overview