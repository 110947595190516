import * as React from "react"

import { WAVY_LINE_COLOR } from 'consts'
import SectionHeader from 'components/molecules/section-header'

import Img1 from 'images/icon--feature1.webp'
import Img2 from 'images/icon--feature2.webp'
import Img3 from 'images/icon--feature3.webp'

const Feature = () => {
  return (
    <section className="feature">
      <SectionHeader
        text='私たちにお任せください'
        lineColor={ WAVY_LINE_COLOR.NAVY }
      />
      <ul className="feature__body">
        <li className="feature__items-box">
          <div>
            <img src={ Img1 } alt="水産業界に特化した採用サポート" className="feature__image" />
          </div>
          <div className="feature__texts-box">
            <h4 className="feature__item-title">水産業界に特化した<br className="sp" />採用サポート</h4>
            <p>
              豊富な水産業界の知識・ノウハウを持った専門チームが貴社の採用活動を全面的にバックアップします。
            </p>
          </div>
        </li>
        <li className="feature__items-box">
          <div>
            <img src={ Img2 } alt="Web媒体の活用による応募数最大化" className="feature__image" />
          </div>
          <div className="feature__texts-box">
            <h4 className="feature__item-title">様々な Web 媒体を<br className="sp" />活用し<br className="pc" />応募を最大化</h4>
            <p>
              何百という WEB 採用媒体の分析を元に、貴社に最適な媒体を選択。登録・出稿・運用も全て私たちが行います。
            </p>
          </div>
        </li>
        <li className="feature__items-box">
          <div>
            <img src={ Img3 } alt="一次対応代行" className="feature__image" />
          </div>
          <div className="feature__texts-box">
            <h4 className="feature__item-title">応募者との<br className="sp" />一次対応も代行</h4>
            <p>
              書類の回収、日程調整などの事務手続きもすべて私たちが代行します。貴社は通常業務に集中できます。
            </p>
          </div>
        </li>
      </ul>
    </section>
  )
}
export default Feature