import * as React from "react"
import PhoneImg from 'images/icon--phone.webp'
import { SP_CALL_BUTTON_TEXT } from 'settings'

/**
 * SPボタン
 * 使用箇所：固定フッター（SP）
 **/ 
export const CallButtonSp = () => {
  return (
    <a href="tel:050-3628-1157">
      <img src={ PhoneImg } alt="phone icon" className="sp-contact-buttons__icon" />
      <span className="sp-contact-buttons__text">{ SP_CALL_BUTTON_TEXT }</span>
    </a>
  )
}