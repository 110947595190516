import * as React from "react"
import { Link } from "gatsby"
import gatsbyConfig from "../../../gatsby-config"
import { LARGE_INQUIRY_BUTTON_TEXT } from 'settings'

/**
 * 共通ボタン（大）
 * 使用箇所：下部（PC, SP）
 **/ 
export const InquiryButtonLarge = () => {
  return (
    <Link
      className="inquiry-button--l"
      to={`${ gatsbyConfig.siteMetadata.siteUrl }/inquiry`}>
      { LARGE_INQUIRY_BUTTON_TEXT }
    </Link>
  )
}