import * as React from "react"

import { WAVY_LINE_COLOR } from 'consts'
import SectionHeader from 'components/molecules/section-header'

const Step = () => {

  const StepNumber = (props) => {
    return (
      <div className="step__number">
        STEP.<span className="step__number--emphasis">{props.number}</span>
      </div>
    )
  }

  return (
    <section className="step">
      <SectionHeader
        text='採用までの流れ'
        lineColor={ WAVY_LINE_COLOR.NAVY }
      />
      <ul>
        <li className="step__item-box">
          <div className="step__item-title-box">
            <StepNumber number='1' />
            <h4 className="step__item-title">キックオフミーティング</h4>
          </div>
          <p className="step__item-text">
            どんな人材が必要か、貴社 にはどんな特徴があるのかを聞かせてください。<br className="pc" />
            私たちがインタビューし<span className="step__text--emphasis">魅力を引き出します</span>。
            写真撮影も行います。
          </p>
        </li>
        <li className="step__item-box">
          <div className="step__item-title-box">
            <StepNumber number='2' />
            <h4 className="step__item-title">求人掲載</h4>
          </div>
          <p className="step__item-text">
            貴社ニーズに即した求人媒体を弊社が選定し掲載。<br className="pc" />
            媒体出稿のための<span className="step__text--emphasis">追加料金は不要</span>です。
          </p>
        </li>
        <li className="step__item-box">
          <div className="step__item-title-box">
            <StepNumber number='3' />
            <h4 className="step__item-title">応募者対応</h4>
          </div>
          <p className="step__item-text">
            応募者には私たちがまず電話等でコミュニケーションをとり、<br className="pc" />
            今回の求人の<span className="step__text--emphasis">説明や事務手続きを代行</span>します。
          </p>
        </li>
        <li className="step__item-box">
          <div className="step__item-title-box">
            <StepNumber number='4' />
            <h4 className="step__item-title">選考</h4>
          </div>
          <p className="step__item-text">
            貴社に書類審査を行っていただき、希望者とは面接を実施していただきます。<br className="pc" />
            面接後の感想や質問等も<span className="step__text--emphasis">私たちが間に立って</span>貴社にお伝えします。
          </p>
        </li>
        <li className="step__item-box">
          <div className="step__item-title-box">
            <StepNumber number='5' />
            <h4 className="step__item-title">採用決定</h4>
          </div>
          <p className="step__item-text">
            お互いに合意すれば、晴れて採用決定です。<br className="pc" />
            契約期間内であれば、<span className="step__text--emphasis">何名採用しても費用は変わりません</span>。
          </p>
        </li>
      </ul>
    </section>
  )
}
export default Step