import * as React from "react"
import { InquiryButtonLarge } from "components/atoms/inquiry-button-large"
import { PhoneAnchor } from "components/atoms/phone-anchor"

const Contact = () => {
  return (
    <section className="contact">
      <ul>
        <li>まずはご相談ください</li>
        <li className="contact__button-box">
          <InquiryButtonLarge />
        </li>
        <li><span className="text--small">TEL</span><PhoneAnchor /></li>
      </ul>
    </section>
  )
}
export default Contact