import * as React from "react"

import { WAVY_LINE_COLOR } from 'consts'
import { ACHIEVEMENT_NUMBERS } from 'settings'

import SectionHeader from 'components/molecules/section-header'
import MapImg from 'images/image--map.webp'

const Achivement = () => {

  const section = 'achivement'

  return (
    <section className={section}>
      <SectionHeader
        text='日本中に広がっています'
        lineColor={ WAVY_LINE_COLOR.WHITE }
      />

      <div className={`${section}__body`}>
        <div className={`${section}__image-box`}>
          <img
            src={ `.${ MapImg }` }
            alt="実績のあるエリア"
            className={`${section}__image`}
          />
        </div>
        <ul className={`${section}__texts-box`}>
          <li>実績エリア<span className={`${section}__numbers`}>{ ACHIEVEMENT_NUMBERS.AREA }</span>都道府県</li>
          <li>応募総数　約<span className={`${section}__numbers`}>{ ACHIEVEMENT_NUMBERS.APPLICANT }</span>名</li>
          <li>入社実績　約<span className={`${section}__numbers`}>{ ACHIEVEMENT_NUMBERS.NEW_EMPLOYEE }</span>名</li>
          <li className={`${section}__note`}>
            未経験・若年層も含め、意欲ある人材の採用を<br className="pc"/>数多く生み出してきました。<br />
            <span className={`${section}__note--timestamp`}>※2021 年10 月現在</span>
          </li>
        </ul>
      </div>
    </section>
  )
}
export default Achivement