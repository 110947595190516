import * as React from "react"

import { PcHeader, SpHeader } from 'components/organisms/header'
import Top from 'components/organisms/top'
import Overview from 'components/organisms/overview'
import Achivement from 'components/organisms/achievement'
import Feature from 'components/organisms/feature'
import Step from 'components/organisms/step'
import Contact from 'components/organisms/contact'
import Company from 'components/organisms/company'
import Project from 'components/organisms/project'
import Footer from 'components/organisms/footer'
import SpContactButtons from 'components/molecules/sp-contact-buttons'

import { FIREBASE_CONFIG } from 'settings'
import { Helmet } from "react-helmet"
import 'styles/index.scss'

// images
import OgImg from 'images/image--top1.webp'
import Icon from 'images/icon.png'

// firebase settings
import { initializeApp } from "firebase/app"
import gatsbyConfig from "../../gatsby-config"

const firebaseConfig = process.env.BUILD_MODE === 'production' ? FIREBASE_CONFIG.PRD : FIREBASE_CONFIG.DEV
initializeApp(firebaseConfig)

const IndexPage = () => {
  return (
    <main className="theme sp-container">
      <Helmet>
        <html lang={ gatsbyConfig.siteMetadata.lang } />
        <title>{ gatsbyConfig.siteMetadata.title }</title>
        <meta name="description" content={ gatsbyConfig.siteMetadata.description } />
        <meta name="keywords" content={ gatsbyConfig.siteMetadata.keywords } />
        <meta name="image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:title" content={ gatsbyConfig.siteMetadata.title } />
        <meta property="og:site_name" content={ gatsbyConfig.siteMetadata.title } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ gatsbyConfig.siteMetadata.siteUrl } />
        <meta property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:description" content={ gatsbyConfig.siteMetadata.description } />
        <meta property="og:locale" content="ja_JP" />
        <link rel="shortcut icon" href={ Icon } type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href={ Icon } />
      </Helmet>

      <PcHeader />
      <SpHeader />

      <Top />
      <Overview />
      <Achivement />
      <Feature />
      <Step />
      <Contact />
      <Company />
      <Project />
      <Footer />
      <SpContactButtons />
    </main>
  )
}

export default IndexPage
