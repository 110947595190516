import * as React from "react"
import { Link } from "gatsby"
import gatsbyConfig from "../../../gatsby-config"
import { MEDIUM_INQUIRY_BUTTON_TEXT } from 'settings'

/**
 * 共通ボタン（中）
 * 使用箇所：ファーストビュー（PC, SP）
 **/ 
export const InquiryButtonMedium = () => {

  return (
    <Link
      className="inquiry-button--m"
      to={`${ gatsbyConfig.siteMetadata.siteUrl }/inquiry`}>
      { MEDIUM_INQUIRY_BUTTON_TEXT }
    </Link>
  )
}